<template>
  <b-modal v-model="isOpen" :show="isOpen" @hide="close">
    <template #modal-header>
      <slot name="header">
        <h5>{{ modalTitle }}</h5>
        <b-button size="sm" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </slot>
    </template>
    <form>
      <div class="center">
        <b-form-group :label="$t('Type') + '*'">
          <b-form-select
            v-model="localUpdateData.type.$model"
            :options="optionsType"
            aria-describedby="type-feedback"
          ></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('NAME') + '*'" label-for="nom">
          <b-form-input
            class="form-control"
            aria-required="true"
            :placeholder="$t('NAME')"
            id="nom"
            required
            :class="{
              'form-control is-invalid': !isNameValid,
              'form-control has-validation': isNameValid,
            }"
            v-model="localUpdateData.nom.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
          ></b-form-input>

          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('SIRET') + '*'" label-for="siret">
          <b-form-input
            class="form-control"
            id="siret"
            :placeholder="$t('SIRET')"
            required
            :class="{
              'form-control is-invalid': !issiretValid,
              'form-control has-validation': issiretValid,
            }"
            v-model="localUpdateData.siret.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="siret-feedback"
          ></b-form-input>

          <div v-if="erreurlist.siret" class="error-message">
            <ul v-if="Array.isArray(erreurlist.siret)">
              <span v-for="(e, index) in erreurlist.siret" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.siret }}</span>
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV') + '*'"
          label-for="paragraphMaPrimeRenov"
        >
          <b-form-textarea
            class="form-control"
            aria-required="true"
            :placeholder="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV')"
            id="paragraphMaPrimeRenov"
            maxlength="1000"
            rows="3"
            required
            :class="{
              'form-control is-invalid': !paragraphMaPrimeRenovValid,
              'form-control has-validation': paragraphMaPrimeRenovValid,
            }"
            v-model="localUpdateData.paragraphMaPrimeRenov.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
          ></b-form-textarea>
          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE') + '*'"
          label-for="paragraphCEE"
        >
          <b-form-textarea
            maxlength="1000"
            rows="3"
            class="form-control"
            aria-required="true"
            :placeholder="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE')"
            id="paragraphCEE"
            required
            :class="{
              'form-control is-invalid': !paragraphCEEValid,
              'form-control has-validation': paragraphCEEValid,
            }"
            v-model="localUpdateData.paragraphCEE.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
          ></b-form-textarea>

          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('Cumac') + '*'" label-for="prixParCumac">
          <b-form-input
          type="text"
          min="0"
          @paste="(event) => onPaste(event, 'localUpdateData.prixParCumac')"
                @keydown="(event) => onKeyDown(event)"
         
            class="form-control"
            aria-required="true"
            :placeholder="$t('Cumac')"
            id="prixParCumac"
            required
            :class="{
              'form-control is-invalid': !isprixParCumacValid,
              'form-control has-validation': isprixParCumacValid,
            }"
            v-model="localUpdateData.prixParCumac.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
          ></b-form-input>

          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="selectedEntreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
            @select="updateEntrepriseId"
          >
            <div v-if="erreurlist.entreprise" class="error-message">
              <ul v-if="Array.isArray(erreurlist.entreprise)">
                <span v-for="(e, index) in erreurlist.entreprise" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.entreprise }}</span>
            </div>
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
        </b-form-group>
      </div>
    </form>

    <template #modal-footer>
      <slot name="footer">
        <div class="double">
          <b-button variant="danger" @click="close">{{
            $t("CANCEL")
          }}</b-button>
          <b-button variant="success" @click="confirm">{{
            $t("SAVE")
          }}</b-button>
        </div>
      </slot>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
export default {
  data() {
    return {
      isNameValid: true,
      isprixParCumacValid: true,
      paragraphCEEValid: true,
      paragraphMaPrimeRenovValid: true,
      issiretValid: true,
      erreurlist: {
        paragraphCEE: [],
        paragraphMaPrimeRenov: [],
        nom: [],
        siret: [],
        entreprise: [],
        type: [],
      },
      selectedEntreprise: null,
      localUpdateData: {},
      optionsType: [
        { value: "mandataire", text: this.$t("AGENT") },
        { value: "delegataire", text: this.$t("DELEGATAIRE") },
        { value: "oblige", text: this.$t("oblige") },
      ],
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: () => null,
    },
  },

  created() {
    this.initializeLocalUpdateData();
  },

  watch: {
    updateData: {
      immediate: true,
      handler() {
        this.initializeLocalUpdateData();
      },
    },
    "localUpdateData.nom.$model"(newValue) {
      this.validateName(newValue);
    },
    "localUpdateData.siret.$model"(newValue) {
      this.validateSiret(newValue);
    },
    "localUpdateData.paragraphCEE.$model"(newValue) {
      this.validateParagraphCEE(newValue);
    },
    "localUpdateData.paragraphMaPrimeRenov.$model"(newValue) {
      this.validateparagraphMaPrimeRenov(newValue);
    },
    "localUpdateData.prixParCumac.$model"(newValue) {
      this.validatePrixParCumac(newValue);
    },
  },

  methods: {
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
  
},

    initializeLocalUpdateData() {
      this.localUpdateData = {
        type: {
          $model: (this.updateData && this.updateData.type) || "mandataire",
          $errors: [],
        },
        nom: {
          $model: (this.updateData && this.updateData.nom) || "",
          $errors: [],
        },
        siret: {
          $model: (this.updateData && this.updateData.siret) || "",
          $errors: [],
        },
        paragraphMaPrimeRenov: {
          $model:
            (this.updateData && this.updateData.paragraphMaPrimeRenov) || "",
          $errors: [],
        },
        paragraphCEE: {
          $model: (this.updateData && this.updateData.paragraphCEE) || "",
          $errors: [],
        },
        prixParCumac: {
          $model: (this.updateData && this.updateData.prixParCumac) || "",
          $errors: [],
        },
        entreprise_id: {
          $model: (this.updateData && this.updateData.entreprise_id) || "",
          $errors: [],
        },
      };
    },

    validateName(newValue) {
      if (!newValue || newValue.length < 1) {
        this.isNameValid = false;
        this.erreurlist.nom = ["Ce champ est obligatoire"];
      } else {
        this.isNameValid = true;
        this.erreurlist.nom = [];
      }
    },

    validateSiret(newValue) {
      if (!newValue) {
        this.issiretValid = false;
        this.erreurlist.siret = ["Ce champ est obligatoire"];
      } else if (newValue.length >= 9 && newValue.length <= 12) {
        this.issiretValid = true;
        this.erreurlist.siret = [];
      } else {
        this.issiretValid = false;
        this.erreurlist.siret = [
          "Le SIRET doit contenir entre 9 et 12 caractères.",
        ];
      }
    },

    validatePrixParCumac(newValue) {
      if (!newValue) {
        this.isprixParCumacValid = false;
        this.erreurlist.prixParCumac = ["Ce champ est obligatoire"];
      } else {
        this.isprixParCumacValid = true;
      }
    },
    validateParagraphCEE(newValue) {
      if (!newValue) {
        this.paragraphCEEValid = false;
        this.erreurlist.paragraphCEE = ["Ce champ est obligatoire"];
      } else {
        this.paragraphCEEValid = true;
      }
    },
    validateparagraphMaPrimeRenov(newValue) {
      if (!newValue) {
        this.paragraphMaPrimeRenovValid = false;
        this.erreurlist.paragraphMaPrimeRenov = ["Ce champ est obligatoire"];
      } else {
        this.paragraphMaPrimeRenovValid = true;
      }
    },
    close() {
      this.$emit("close");
    },

    confirm() {
      this.validateName(this.localUpdateData.nom.$model);
      this.validateSiret(this.localUpdateData.siret.$model);
      this.validateParagraphCEE(this.localUpdateData.paragraphCEE.$model);
      this.validateparagraphMaPrimeRenov(
        this.localUpdateData.paragraphMaPrimeRenov.$model
      );
      this.validatePrixParCumac(this.localUpdateData.prixParCumac.$model);
      if (
        !this.isNameValid ||
        !this.issiretValid ||
        !this.paragraphCEEValid ||
        !this.paragraphMaPrimeRenovValid ||
        !this.isprixParCumacValid
      ) {
        return;
      }

      if (this.selectedEntreprise) {
        this.localUpdateData.entreprise_id.$model = this.selectedEntreprise.id;
      } else {
        this.localUpdateData.entreprise_id.$model =
          this.getOnlineUser.entreprise.id;
      }

      this.$emit("confirm", {
        type: this.localUpdateData.type.$model,
        nom: this.localUpdateData.nom.$model,
        siret: this.localUpdateData.siret.$model,
        paragraphCEE: this.localUpdateData.paragraphCEE.$model,
        paragraphMaPrimeRenov:
          this.localUpdateData.paragraphMaPrimeRenov.$model,
        prixParCumac: this.localUpdateData.prixParCumac.$model,
        entreprise_id: this.localUpdateData.entreprise_id.$model,
      });

      this.close();
    },
  },

  computed: {
    ...mapGetters(["getAllentreprises", "getOnlineUser"]),
    modalTitle() {
      return this.updateData && Object.keys(this.updateData).length > 0
        ? this.$t("UPDATE_EXTERNAL_ENTITY")
        : this.$t("ADD_EXTERNAL_ENTITY");
    },
    isSuperAdmin() {
      return this.getOnlineUser.role === "user.super-admin";
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
