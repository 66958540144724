export function preventExceedingLength(event, allowedPattern = /[0-9]/, additionalAllowedKeys = ['Backspace', 'Delete']) {
  const disallowedSequences = ['-', '+', '--', '++', '//'];
  if (disallowedSequences.includes(event.key)) {
    event.preventDefault();
    return;
  }
  if (!allowedPattern.test(event.key) && !additionalAllowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}

export function handlePaste(event, targetField, allowedPattern = /[^0-9]/) {
  const pasteContent = event.clipboardData.getData('text');
  const cleanedContent = pasteContent.replace(allowedPattern, '');
  if (cleanedContent === '') {
    event.preventDefault();
    return;
  }
  event.preventDefault();
  event.target.value = cleanedContent;
  this[targetField] = cleanedContent;
}
export function preventExceedingLengthFloat(event, allowedPattern = /[0-9]/, additionalAllowedKeys = ['Backspace', 'Delete', 'Tab']) {
  const disallowedSequences = ['-', '+', '--', '++', '//'];

  const currentValue = event.target.value;
  const isDecimal = event.key === '.' && !currentValue.includes('.');

  if (disallowedSequences.includes(event.key)) {
    event.preventDefault();
    return;
  }

  if (
    !allowedPattern.test(event.key) &&
    !additionalAllowedKeys.includes(event.key) &&
    !isDecimal
  ) {
    event.preventDefault();
  }
}